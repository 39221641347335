import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './components/layout/Header';
import About from './components/pages/about/About';
import Locations from './components/pages/locations/Locations';
import ContactUs from './components/pages/contactUs/ContactUs';
import Home from './Home';
import './App.css';
import { Link } from 'react-router-dom';
import doctorTools from './image-files/doctortools.jpg';

class App extends React.Component {
  render () {
    return (
      <BrowserRouter>
        <Header/>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/locations" element={<Locations />} />
        </Routes>
        <ContactUs/>
      </BrowserRouter>
      
    );
  }
}
export default App;
