import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './styles.css'


const Header = () => {

  const [menuShown, setmenuShown] = useState(true);

  const showMenu = () => setmenuShown(!menuShown);

  const [mobile, setMobile] = useState(typeof window !== 'undefined' && window.innerWidth <= 1024);

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 1024);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

    return (
      <header className='header'>
        <h1 className='title'>Core Continuity of Care</h1>
        <button className='compact' onClick={showMenu}>
         <div className="bar1"></div>
         <div className="bar2"></div>
         <div className="bar3"></div>
        </button>

        {
          menuShown || !mobile
            ? (
                <ul className='links-list'>
                  <Link className='link-style' to="/">Home</Link>
                  <Link className='link-style' to="/about">About Us</Link>
                  {/* <Link className='link-style' to="/services">Services</Link> */}
                  <Link className='link-style' to="/locations">Locations</Link>
                  {/* <Link className='link-style' to="/contactUs">Contact</Link> */}
                </ul>
        )
        : (
          null
        )
      }
      </header>
    );
}

export default Header;
